class HamburgerMenu {
  private hamburgerTrigger: HTMLElement;
  private hamburgerTarget: HTMLElement;
  private searchTrigger: HTMLElement;
  private searchForm: HTMLElement;

  constructor() {
    this.hamburgerTrigger = document.querySelector('[data-hamburger-trigger]')!;
    this.hamburgerTarget = document.querySelector('[data-hamburger-target]')!;
    this.searchTrigger = document.querySelector('[data-search-trigger]')!;
    this.searchForm = document.querySelector('[data-search-form]')!;
    this.init();
  }

  init() {
    this.hamburgerTrigger.addEventListener('click', () => {
      this.hamburgerTarget.classList.toggle('opacity-0');
      this.hamburgerTarget.classList.toggle('-translate-y-full');

      this.hamburgerTrigger.querySelector('svg:first-child')!.classList.toggle('hidden');
      this.hamburgerTrigger.querySelector('svg:last-child')!.classList.toggle('hidden');

      // Dodanie scrollowania na mobile
      if (window.innerWidth < 768) { // breakpoint md w Tailwind
        const menuOffset = document.querySelector('[data-menu-offset]');
        if (menuOffset) {
          const rect = menuOffset.getBoundingClientRect();
          if (rect.top > 0) {
            window.scrollTo({
              top: window.scrollY + rect.top,
              behavior: 'smooth'
            });
          }
        }
      }
    });

    this.searchTrigger.addEventListener('click', () => {
      this.searchForm.classList.toggle('md:hidden');
    });
  }
}

window.addEventListener('DOMContentLoaded', () => {
  new HamburgerMenu();

  if ('loading' in HTMLImageElement.prototype) {
    const images = document.querySelectorAll<HTMLImageElement>('img[loading="lazy"]');
    images.forEach(img => {
      img.src = img.dataset.src!;
    });
  } else {
    // Dynamically import the LazySizes library
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
    document.body.appendChild(script);
  }

  if (document.getElementById('aqi-widget-container')) {
    (async () => {
      const { AQIWidget } = await import('./modules/AQIWidget');
      new AQIWidget();
    })();
  }

  async function tryToRefreshFsLightbox() {
    //@ts-expect-error unknown function
    if(typeof refreshFsLightbox === 'function') {
      //@ts-expect-error unknown function
      refreshFsLightbox();
    } else {
      //@ts-expect-error fslightbox is not typed
      await import('./libs/fslightbox.min.js');
      //@ts-expect-error unknown function
      refreshFsLightbox();
    }
  }

  if (document.querySelector('img[data-orig-file]')) {
    // Funkcja naprawiająca źródła obrazów
    const fixUndefinedSources = () => {
      Array.from(document.querySelectorAll<HTMLImageElement>('img[data-orig-file]')).forEach(el => {
        const { origFile } = el.dataset;
        if (!origFile) return;
        el.src = origFile;
        if(el.parentNode instanceof HTMLAnchorElement) {
          el.parentNode.setAttribute('data-fslightbox', '1');
          el.parentNode.setAttribute('href', origFile);
        }
      });
      
      tryToRefreshFsLightbox();
    };

    // Wywołanie początkowe
    fixUndefinedSources();
  }

  if (document.querySelector('[data-fslightbox]')) {
    tryToRefreshFsLightbox();
  }
});

// window.addEventListener('load', () => {
//   (async () => {
//     const advertContainers = document.querySelectorAll<HTMLElement>('.advert-container');
//     for (const container of advertContainers) {
//       const { AdvertManager } = await import('./modules/AdvertManager');
//       new AdvertManager(container);
//     }
//   })();
// });
